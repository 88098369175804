import "core-js/modules/es.object.assign.js";
import { provide, ref } from 'vue';
import Theme from '@eaphone/style/theme.vue';
var __default__ = {};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'app',
  setup: function setup(__props, _ref) {
    var __expose = _ref.expose;
    __expose();
    var cache = ref({});
    provide('form-cache', cache);
    var __returned__ = {
      cache: cache,
      provide: provide,
      ref: ref,
      Theme: Theme
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
});